@font-face {
  font-family: "HelveticaNeue";
  src: url("../font/HelveticaNeue.woff") format("woff");
}
@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
$blue: #384569;
$yellow: #FCD882;
