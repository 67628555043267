@import './variables.scss';

footer {
  background-color: $yellow;
  color: $blue;
  padding: 1rem 0;
  font-size: 12px;
  text-align: center;
  margin-top: 1rem;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
