@import "./variables.scss";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    max-width: 1200px;
  }
}
.skillList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .skill {
    margin: 2rem;
    width: 40px;
  }
}
.bio {
  color: $yellow;
  max-width: 400px;
  line-height: 1.5;
  white-space: pre-line;
  margin: 0 auto;
  text-align: justify;
  padding: 0 1.5rem;
}
@media (min-width: 769px) {
  .content {
    margin-bottom: 3rem;
  }
}
