@import './variables.scss';

.close {
  display: none;
}
.open {
  display: block;
}
.desktop {
  display: none;
}
header {
  position: absolute;
  width: 100%;
  .menuButton {
    margin: 1rem 0 0 1rem;
    color: $blue;
    font-size: 30px;
  }

  ul.mobileMenu {
    animation: fadeIn 0.3s ease-in-out;
    width: 100%;
    li{
      list-style: none;
      margin: 1rem 0;
      border-bottom: 1px solid $blue;
      a {
        color: $blue;
        text-decoration: none;
        span {
          font-weight: 200;
        }
      }
    }
  }

  ul.desktopMenu {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 700px;
    margin: 2rem auto;
    li{
      list-style: none;
      a {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}
@media (min-width: 769px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: flex;
  }
}
