@import "./variables.scss";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $yellow;
}
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  .projectLink {
    display: flex;
  }
  .projectLogo {
    width: 250px;
    margin: 2rem;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.content {
  width: 100%;
  max-width: 1200px;
}
@media (min-width: 430px) {
  .projects {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-around;
    margin-bottom: 3rem;
  }
}
