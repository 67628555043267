@import './variables.scss';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }
}
.avatar {
  width: calc(7rem + 7vmax);
  padding-top: calc(7rem + 7vmax);
  background-size: cover;
  margin: 0 auto;
}
.contacts {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: min-content;
}
.contactDetails {
  a {
    color: $yellow;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    margin: 1rem 0;
    i {
      font-size: 20px;
      margin-right: 0.5rem;
    }
  }
}
