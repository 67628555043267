@import "./variables.scss";

* {
  font-family: Helvetica;
}
body {
  background-color: $blue;
  margin: 0;
}
span, p {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.blue {
  color: $blue;
}
.blueOutline {
  color: $yellow;
  text-shadow:
   -1px -1px 0 $blue,
    1px -1px 0 $blue,
    -1px 1px 0 $blue,
     1px 1px 0 $blue;
}
.yellowOutline {
  color: $blue;
  text-shadow:
   -1px -1px 0 $yellow,
    1px -1px 0 $yellow,
    -1px 1px 0 $yellow,
     1px 1px 0 $yellow;
}
.yellow {
  color: $yellow;
}
.bold {
  font-weight: 900;
}
.light {
  font-weight: 200;
}
h1 {
  font-size: calc(10px + 10vmin);
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.inline {
  display: flex;
}
