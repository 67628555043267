@import './variables.scss';

section.wrap {
  background-color: $yellow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  span {
    display: flex;
  }
  * {
    animation: fadeIn 1s ease-in-out;
  }
}
.slogan {
  text-transform: uppercase;
}
